import React, { useState } from "react";
import { PopupButton } from "react-calendly";

const FORM_ENDPOINT = "https://public.herotofu.com/v1/02266ab0-5019-11ed-8970-6943e4ac8982";

const Contact = () => {
  const [submitted, setSubmitted] = useState(false);
  const handleSubmit = () => {
    setTimeout(() => {
      setSubmitted(true);
    }, 100);
  };

  if (submitted) {
    return (
      <>
        <div className="text-2xl">Thank you!</div>
        <div className="text-md">We'll be in touch soon.</div>
      </>
    );
  }
  return (
    <div className="container">
        <div className="row">
            <div className="col-md-6 text-light">
                <p id="contact-text">
                    To discuss working together, you can 
                    <PopupButton className="link button-link" url="https://calendly.com/timothyreavis/30min"
                    rootElement={document.getElementById("root")}
                    text="schedule a meeting with me" />or enter your contact information in the provided form.
                </p>
                <p>
                    Be sure to share as many details as possible regarding the role, its project, what you're trying to achieve, and the sort of budget you have in place to fill the role.
                </p>
            </div>
            <div className="col-md-6">
                <form className="form-sm text-dark" action={FORM_ENDPOINT}
                    onSubmit={handleSubmit}
                    method="POST"
                    target="_blank">
                    <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="name" name="name" aria-describedby="Name" placeholder="Name"/>
                        <label htmlFor="name">Name</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="email" className="form-control" id="email" name="email" aria-describedby="Email" placeholder="Email"/>
                        <label htmlFor="email">Email</label>
                    </div>
                    <div className="form-floating mb-3">
                        <input type="phone" className="form-control" id="phone" name="phone" aria-describedby="Phone" placeholder="Phone"/>
                        <label htmlFor="phone">Phone</label>
                    </div>
                    <div className="form-floating mb-3">
                        <textarea className="form-control" id="inquiry" name="inquiry" rows="3" placeholder="Inquiry" style={{height: "100px"}}></textarea>
                        <label htmlFor="inquiry">Inquiry</label>
                    </div>
                    <button type="submit" className="btn btn-dark">Submit</button>
                </form>
            </div>
        </div>
    </div>
  )
}

export default Contact
