import React, { useState, useEffect } from 'react';
import { Container, Col } from 'react-bootstrap';
import Contact from './Contact';
import Project from './Project';

const Resume = ({work}) => {
    return (
        <Container>
            <Col>
                <h2 className='fw-light text-uppercase'>Experience</h2>
                <ul className="navbar-nav m-auto">
                {work.map((item, index) => (
                    <li key={index} className="nav-item">
                        <h5 className="mt-3">{item.title}</h5>
                        <p><small>{item.company} // {item.date}</small></p>
                        <ul>
                        {item.description.map((desc) => 
                            <li key={desc}>{desc}</li>
                        )}
                        </ul>
                    </li>
                ))}
                </ul>
            </Col>
        </Container>
    )
}

export default Resume