import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, ButtonGroup } from 'react-bootstrap'

const ChangeTheme = () => {
    return (
            <Container>
              <Row>
                <Col lg="4"></Col>
                <Col lg="4" className='text-center'>
                  <ButtonGroup className='centered' aria-label="Switch between themes">
                    <Button variant="dark">Professional</Button>
                    <Button variant="secondary">Personal</Button>
                  </ButtonGroup>
                </Col>
                <Col lg="4"></Col>
              </Row>
            </Container>
    );
}

export default ChangeTheme