import React, { useState } from 'react';
import { Route, NavLink, BrowserRouter as Router, Routes, Link, useLocation } from "react-router-dom"
import { Container, Navbar, Offcanvas, Nav, Row, Col } from 'react-bootstrap'
import { BsTwitter } from 'react-icons/bs'
import { BsInstagram } from 'react-icons/bs'
import { BsLinkedin } from 'react-icons/bs'
import ChangeTheme from './ChangeTheme'
import Branding from './Branding'
import { useEffect } from 'react';

const NavbarComponent = () => {

  const expand = false;
  const { pathname } = useLocation();
    
  return <div>
    <Navbar key={expand} bg="dark" varient="dark" className='navbar-dark' expand={expand}>
      <Container fluid>
        { pathname === "/resume" ? null : <Branding /> }
        {/* { pathname === "/" ? <ChangeTheme /> : null } */}
        <Navbar.Toggle id='navburger' aria-controls={`offcanvasNavbar-expand-${expand}`}>
          <svg className="mt-2 mb-2" width="20px" height="12px" viewBox="0 0 20 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g fill="#fff">
                <g>
                  <rect x="0" y="0" width="20" height="2"></rect>
                  <rect x="6" y="5" width="14" height="2"></rect>
                  <rect x="0" y="10" width="20" height="2"></rect>
                </g>
              </g>
            </g>
          </svg>
        </Navbar.Toggle>
        <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end">
          <Navbar.Toggle>
          <div className='offcanvas-header bg-dark'>
            <a className="btn-close btn-close-white btn-dark text-reset" />
          </div>
          </Navbar.Toggle>
          <div className="offcanvas-body bg-dark">
            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
              <li className="nav-item">
                <Navbar.Toggle>
                  <NavLink className='nav-link' to="/" end>Home</NavLink>
                </Navbar.Toggle>
              </li>
              <li className="nav-item">
                <Navbar.Toggle>
                  <NavLink className='nav-link' to="/resume">Resume</NavLink>
                </Navbar.Toggle>
              </li>
              {/* <li className="nav-item">
                <NavLink className='nav-link' to="/blog">Blog</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className='nav-link' to="/store">Store</NavLink>
              </li> */}
              <li className="nav-item">
                <Navbar.Toggle>
                  <NavLink className='nav-link' to="/contact">Hire Me</NavLink>
                </Navbar.Toggle>
              </li>
            </ul>
            <div className="navbar">
              <div className="navbar-nav flex-row list-inline">
                <li className="nav-item list-inline-item pe-3">
                  <a className="nav-link  link-light" target="_blank" rel="noopener" href="https://twitter.com/timothyreavis"><BsTwitter /></a>
                </li>
                <li className="nav-item list-inline-item pe-3">
                  <a className="nav-link" target="_blank" rel="noopener" href="https://instagram.com/musicalcomputergeek"><BsInstagram /></a>
                </li>
                <li className="nav-item list-inline-item pe-3">
                  <a className="nav-link" target="_blank" rel="noopener" href="https://linkedin.com/in/timothyreavis"><BsLinkedin /></a>
                </li>
              </div>
            </div>
          </div>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
    <div className="navbar navbar-expand-lg navbar-dark bg-dark mb-3">
      <Container fluid>
        {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <Row className="collapse navbar-collapse" id="navbarSupportedContent">
          <Col>
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink className='nav-link' to="/" end>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className='nav-link' to="/resume">
                  Resume
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink className='nav-link' to="/blog">
                  Blog
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className='nav-link' to="/store">
                  Store
                </NavLink>
              </li> */}
              <li className="nav-item">
                <NavLink className='nav-link' to="/contact">
                  Hire Me
                </NavLink>
              </li>
            </ul>
          </Col>
          <Col>
            <ul className="navbar-nav justify-content-end">
              <li className="nav-item">
                <a className="nav-link" target="_blank" rel="noopener" href="https://twitter.com/timothyreavis"><BsTwitter /></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" target="_blank" rel="noopener" href="https://instagram.com/musicalcomputergeek"><BsInstagram /></a>
              </li>
              <li className="nav-item">
                <a className="nav-link" target="_blank" rel="noopener" href="https://linkedin.com/in/timothyreavis"><BsLinkedin /></a>
              </li>
            </ul>
          </Col>
          { pathname === "/resume" ? <Row className='text-center'><Branding /></Row> : null }
        </Row>
        <hr />
      </Container>
    </div>
  </div>
}

export default NavbarComponent
