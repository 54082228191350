import { NavLink } from "react-router-dom"

const Branding = () => {
    return (
        <NavLink className="navbar-brand mb-3 mt-3" to="/">
        <h1>
            <span className='display-6'>TIMOTHY</span> REAVIS
        </h1>
        <h6 className='subheading text-center'>Senior Software Engineer</h6>
        </NavLink>
    )
}

export default Branding