import React from 'react';
import { FiArrowRight } from 'react-icons/fi';

const Post = (props) => {
    const { title, link, thumbnail, content, pubDate } = props.blogData;

    function blogPost() {
        return <div>
          <a href={`${link}`} className="list-group-item list-group-item-action">
            <div className="ms-2 me-auto">
              <div className="fw-bold">{cleanTitle(title)}</div>
              <p>{truncateText(toText(content), 0, 100)}</p>
            </div>
          </a>
            {/* <div className="card-img-top cover-img">
                <img src={`${thumbnail}`} className="img-fluid img-fit rounded" alt={cleanTitle(title)}></img>
            </div>
            <div className="card-body p-0">
              <a className='text-decoration-none' target="_blank" rel="noopener" href={`${link}`}>
                <h5 className='card-title fw-light'>{cleanTitle(title)} <FiArrowRight /></h5>
              </a>
              <p className='card-text text-light'>{truncateText(toText(content), 0, 100) + "..."}</p>
            </div> */}
          </div>
    }

    function cleanTitle(checkTitle) {
        checkTitle = checkTitle.replace("amp;", "");
        return checkTitle
    }

    function truncateText(text, start, len) {
        return text.length > len ? text.slice(start, len) : text;
    }

    function toText(block) {
        let tag = document.createElement('div');
        tag.innerHTML = block;
        block = tag.innerText;
        return block
    }

    function convertDate(date) {
        let dateArray = date.slice(0, 10).split("-")
        let year = dateArray.shift();
        dateArray.push(year)
        return `${dateArray.join(".")}`;
    }

    return (
      <div className=''>
        <div className="">
            {blogPost()}
        </div>
      </div>
    );
}

export default Post;