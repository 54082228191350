import React, { useState, useEffect } from 'react';
import Post from './Post';

const Blog = () => {
    // const mediumUrl = "https://medium.com/feed/@joshuagauthreaux"
    const rss2json = "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40uxmisfit";
    const [myBlog, setMyBlog] = useState([]);

    useEffect(() => {
        fetch(rss2json)
            .then(res => res.json())
            .then(data => {
                setMyBlog(data)
            })
    }, [rss2json]);

    function displayBlogs() {
        
        return myBlog.items && myBlog.items.map(blog => {
            return blog.categories.length > 0 && <Post key={blog.pubDate} blogData={blog} />
        })
    }

    return (
            <div className="container">
              <div className='row'>
                  <div className='col-md-8'></div>
                  <div className='col-md-4'>
                      <div className='list-group'>
                          {displayBlogs()}
                      </div>
                  </div>
              </div>
            </div>
    );
}

export default Blog;