import { NavLink, Link } from 'react-router-dom'
import { FaChrome } from 'react-icons/fa'
import { FaSearchDollar } from 'react-icons/fa'
import { FaAd } from 'react-icons/fa'

const Professional = () => {
    return (
        <div className='container text-light'>
            <div className='row'>
                <div className='col-10'>
                    <h2 className='fw-semibold fs-5 mb-3'>
                        <p>I'm Timothy Reavis — a full-stack web developer based in Arizona.</p>
                        <p className='fw-normal'> I develop <b>web apps</b> and <b>digital solutions </b> for a wide range of organizations, from founding <a className='link' href="https://reavisdigital.com" target="_blank">Reavis Digital</a>, an agency that develops websites for small businesses, to working as a full-time contractor for the US Army, Navy, and Air Force.</p>
                    </h2>
                    <p className='mt-4'><b>Along with web development, 
                        I also work in a variety of other sectors of programming and IT. </b>
                        Here are a few of my other specialties:
                    </p>
                    <ul className='fw-normal'>
                        <li>Windows app development (WPF)</li>
                        <li>Software quality assurance</li>
                        <li>Sales engineering and product owner relations</li>
                    </ul>
                    <p>
                        It doesn't matter what role you're looking to fill, I can learn how to do the job. If it requires an IT professional that can deliver results,
                        <span className='fw-bold'> I can help.</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Professional