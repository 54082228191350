import './App.css';
import { NavLink, BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import NavbarComponent from './components/NavbarComponent'
import Blog from './components/Blog'
import Resume from './components/Resume'
import Contact from './components/Contact'
import Professional from './components/Professional';

function App() {
  const work = [
    {
      title: "Senior Software Engineer",
      date: "March 2021 - Present",
      company: "LinTech Global, Remote",
      description: [
        "Developed and maintained U.S. Army Web applications using .NET Core, C#, Entity Framework, MS SQL",
        "Rewrote legacy ASP.NET application into .NET Core, Entity Framework, Bootstrap 5",
        "Collaborated remotely with product owners, customers, and developers to set and meet mission requirements"
      ]
    },
    {
      title: "Software Engineer",
      date: "July 2020 - December 2020",
      company: "Capstone Corporation, Remote",
      description: [
        "Developed and maintained U.S. Navy financial Web applications using ASP.NET, C#, MS SQL, Jira // Worked closely with a remote team to complete tickets for new features and existing functionality",
        "Prototyped new front end interface for improved usability, speed for customer",
        "Followed Agile development principles to meet team goals and accomplish customer satisfaction"
      ]
    },
    {
      title: "Software Engineer",
      date: "May 2018 - Dec 2019",
      company: "Excellus/Mission Services, Robins Air Force Base, GA",
      description: [
        "Developed, maintained internal U.S. Air Force WebForms applications using ASP.NET C#, jQuery, MS SQL",
        "Worked closely with customers and product owners to gather requirements and ensure satisfaction",
        "Designed new front end interface for improved usability, speed, and information security // Scanned solution code for security vulnerabilities with HPE Fortify and worked to eliminate findings",
        "Deployed new software releases to production Windows Server and configured IIS environments"
      ]
    },
    {
      title: "Software Engineer",
      date: "Oct 2016 - May 2018",
      company: "Akira/Indrasoft/Yakshna, Robins Air Force Base, GA",
      description: [
        "Administered software to conduct the simulation of missile threats and aircraft countermeasure effectiveness",
        "Administered and developed internal U.S. Air Force Web applications using MVC, JSP, and Oracle database",
        "Designed and developed next-generation mission planning software for UH-1N helicopter with C# and WPF"
      ]
    },
    ];
  return (
    <Router>
      <div className='container'>
        <NavbarComponent />
        <div className='container pb-3'>
          <Routes>
            <Route 
              path='/' 
              element = {
                <div>
                  <Professional />
                </div>
              }
            />
            <Route path="/resume" element={<Resume work={work} />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
